import React, { Component } from "react";

class Careers extends Component {
    render(){
        return(
            <div className = "content-wrapper">
            <p className="pageContent">hello, careers </p>
            <div class="push"></div>
            </div>
        )
    }
}

export default Careers;