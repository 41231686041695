import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./App.css";
import Home from "./components/Home";
import Careers from "./components/Careers";
import About from "./components/About";
import Contact from "./components/Contact";
import Ribbon from "./components/Ribbon";
import Footer from "./components/Footer"

export default function App(){

    return (
      <Router>
        <Ribbon/>
        <div className="App">
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route exact path="/about">
              <About />
            </Route>
            <Route exact path="/careers">
              <Careers />
            </Route>
            <Route exact path="/contact">
              <Contact />
            </Route>
            <Route exact path="/privacyPolicy">
              {/* privacy policy component - not yet created */}
            </Route>
          </Switch>
        </div>
        <Footer/>
      </Router>
    );
  
}
